body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #212121;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.niu {
  position: absolute;
  top: 0;
  height:100vh;
  width:100vw;
  right: -8%;
  z-index: -1;
}

.home_btn {
  margin-top: 33px !important;
  width: 500px;
  height: 55px;
  background: #34AADC !important;
  border-radius: 100px !important;
  font-size: 18px;
  color: #FAFAFA !important;
  letter-spacing: 0.85px;
  outline: none !important;
  border: none !important;
  text-align: center;
  cursor: pointer;
  z-index: 10;
  position: relative;
}

.screen_audio {
  color: #FAFAFA;
}
.style_input__3aBjE {
  width: 500px;
  height: 55px;
  background: #49494B;
  border-radius: 51px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 35px;
  z-index: 10;
  position: relative;
}

.style_input__3aBjE input, select {
  opacity: 0.86;
  font-family: PingFangSC-Light;
  font-size: 14px;
  color: #FAFAFA;
  height: 100%;
  width: 100%;
  letter-spacing: 0.66px;
  text-align: left;
  background: none;
  outline: none;
  border: none;
}
.hint {
  opacity: 0.68;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.62px;
  text-align: left;
  width: 100%;
  padding-left: 35px;
  margin-bottom: 5px;
}

.home_user {
  margin-bottom: 38px;
  opacity: 0.61;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.41px;
  text-align: center;
}
.qnMobile{
    position: relative;
    top:0;
    overflow: scroll;
}
.qnMobile .input {
    width:90vw;
    max-width: 500px;
}
.qnMobile .home_btn{
    width: 90vw;
    max-width: 500px;
}
.qnMobile .niu {
    top:0;
    left:0;
    width:100%
}
.qnMobule .style_input{
    width:90vw
}
.qnMobile .radioGroup{
    width:100vw
}

.col-2 {
    width: 16.666%;
}
  
.col-4 {
    width: 33.333%;
}

.col-6 {
    width: 50%;
}

.col-12 {
    width: 100%;
}

.row-4 {
    height: 33.33%;
}

.row-6 {
    height: 50%;
}

.row-12 {
    height: 100%;
}
.style_input__BeFPf {
  width: 500px;
  height: 55px;
  background: #49494B;
  border-radius: 51px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 35px;
  z-index: 10;
  position: relative;
}

.style_input__BeFPf select {
  opacity: 0.86;
  font-family: PingFangSC-Light;
  font-size: 14px;
  color: #FAFAFA;
  height: 100%;
  width: 100%;
  letter-spacing: 0.66px;
  text-align: left;
  background: none;
  outline: none;
  border: none;
}

.UserPlayer_root__1Jx-0 {
  position: relative;
  background: #000;
  width: 200px;
  height: 150px;
}
.UserPlayer_rootMobile__3Oo1K {
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
}
.UserPlayer_rootMobile__3Oo1K video {
  object-fit: cover!important;
}
.UserPlayer_zoom__1q2S2 {
  width: 200px;
  height: 150px;
}
.UserPlayer_zoomMobile__5D6nX {
  width: 100%;
  height: 100%;
}
.UserPlayer_screen__3A17T {
  width: 100%;
  height: 100%;
}
.UserPlayer_rootMobile__3Oo1K>div {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.UserPlayer_rootMobile__3Oo1K>div:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.UserPlayer_rootMobile__3Oo1K>div:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
}
.UserPlayer_rootMobile__3Oo1K>div:nth-child(3) {
  display: none;
}
.UserPlayer_root__1Jx-0>div {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.UserPlayer_root__1Jx-0>div:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.UserPlayer_root__1Jx-0>div:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
}
.UserPlayer_root__1Jx-0>div:nth-child(3) {
  display: none;
}
.UserPlayer_userName__HGfDP {
  left: 0;
  width: 100%;
  position: absolute;
  text-align: center;
}

.InfoPanel_infoPanel__1X6al {
  background: rgba(28,28,28,0.8);
  border-radius: 4px;
  color: #fff;
  left: 10px;
  position: absolute;
  top: 60px;
  z-index: 64;
  min-width: 22em;
  font-size: 11px;
}
.InfoPanel_infoPanelMobile__B2abs {
  background: rgba(28,28,28,0.8);
  border-radius: 4px;
  color: #fff;
  right: 0;
  position: absolute;
  top: 58px;
  z-index: 64;
  min-width: 17em;
  font-size: 11px;
}

.InfoPanel_infoPanelContent__1jNwK {
  padding: 5px;
}

.InfoPanel_infoPanelContent__1jNwK>div>div {
  display: inline-block;
  font-weight: 500;
  padding: 0 .5em;
  text-align: right;
  width: 10em;
}

.SwitchPanel_switchPanel__2UaRs {
  background: rgba(28,28,28,0.8);
  border-radius: 4px;
  color: #fff;
  left: 10px;
  position: absolute;
  top: 200px;
  z-index: 64;
  min-width: 22em;
  font-size: 11px;
  padding: 5px;
}
.SwitchPanel_switchPanelMobile__2XsDS {
  background: rgba(28,28,28,0.8);
  border-radius: 4px;
  color: #fff;
  right: 0;
  position: absolute;
  top: 0;
  z-index: 64;
  min-width: 22em;
  font-size: 11px;
  padding: 5px;
}
.SwitchPanel_formControl__3lxUM {
  display: block;
}
.SwitchPanel_formControlMobile__1_J2R {
  display: block;
  width: calc( 50vw - 5px)
}
.SwitchPanel_formControl__3lxUM > .SwitchPanel_select__kLWe3 {
  min-width: 120px;
}

