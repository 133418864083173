
.root {
  position: relative;
  background: #000;
  width: 200px;
  height: 150px;
}
.rootMobile {
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
}
.rootMobile video {
  object-fit: cover!important;
}
.zoom {
  width: 200px;
  height: 150px;
}
.zoomMobile {
  width: 100%;
  height: 100%;
}
.screen {
  width: 100%;
  height: 100%;
}
.rootMobile>div {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.rootMobile>div:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.rootMobile>div:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
}
.rootMobile>div:nth-child(3) {
  display: none;
}
.root>div {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.root>div:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.root>div:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
}
.root>div:nth-child(3) {
  display: none;
}
.userName {
  left: 0;
  width: 100%;
  position: absolute;
  text-align: center;
}